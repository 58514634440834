body {
    background: #fff;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #444;
}

/* ==========================================================================
navbar text
========================================================================== */
.navbar-default .navbar-brand {
    color: #444;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 45px;
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a {
    color: #1c0cf5;
}

a:hover, .hand, [jh-sort-by] {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}



/* jhipster-needle-css-add-main JHipster will add new css style */
.panel.noborder {
    border: none;
    box-shadow: none;
}
.panel.noborder > .panel-heading {
    border: 1px solid #dddddd;
    border-radius: 0;
}

.top-margin {
    margin-top:10px;
}

.currentPepper {
    background-color: lightskyblue;
}


/* ==========================================================================
upload
========================================================================== */
.drop-box {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    height: 100px;
    line-height: 100px;
    text-align: center;
}
.dragover {
    border: 5px dashed blue;
}
.progressBar {
    display: inline-block;
    width: 100%;
    border: 3px groove #CCC;
}
.progressBar div {
    font-size: smaller;
    width: 0;
    height: 26px;
    line-height: 26px;
}
.btn-group {
    margin-right: 3px;
}
.bg-white1 {
    background-color: white;
}

/* ==========================================================================
message
========================================================================== */
.message {
    right: -260px;
    top: 100px;
    width: 260px;
    height: 30%;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    position: fixed;
    animation: 10s;
    z-index:999;
}
.message_top {
    background-color: #ffffff;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #e3e3e3;
    text-align: right;
    line-height: 30px;
}
.message_title {
    width: 80%;
    height: 30px;
    text-align: left;
    line-height: 30px;
    float: left;
    padding-left: 20px;
}
.message_close {
    width: 20%;
    float: left;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}
.message_content {
    width: 100%;
    margin: 10px;
    float: left;
}
.message_text {
    width: 80%;
    float: left;
    background-color: #FFFFFF;
    padding: 10px;
}
.message_time {
    width: 20%;
    float: left;
}
.message_info {
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
}
.message_warning {
    background-color: #8a6d3b;
    border-color: #fcf8e3;
    color: #8a6d3b;
}
.message_error {
    background-color: #f2dede;
    color: #a94442;
    border-color: #ebccd1;
}
.message_success {
    background-color: #dff0d8;
    color: #3c763d;
    border-color: #d6e9c6;
}

.staff-no-photo  {
    display: inline-block;
    width: 231px;
    height: 250px;
    background: url("../images/staff.png") no-repeat center top;
    background-size: contain;
}

.staff-photo {
    display: inline-block;
    width: 231px;
    height: 250px;
    background-size: contain;
}

.clock {
    height: 230px;
}

.hour-timer {
    font-size: 22px;
}

.date-timer {

}

.btn-timer {
    width: 150px;
    font-size:40px;
}
.group-name {
    width:400px;
    height:600px;
    font-size:22px;
}

.ng-camera-stack {
    position: relative;
}
.ng-camera-overlay {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;
}

#ng-camera-action {
    margin-top:15px;
    margin-left:100px;
}

.photo {
    display: inline-block;
    width: 231px;
    height: 250px;
    background-size:contain;
}

.modal-full-screen {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.modal-full-screen .modal-content {
    height: auto;
    min-height: 100%;
    border-radius:0;
}

.modal-full-screen .panel-timecard-left {
    min-height: 80vh;

}

.modal-full-screen .panel-timecard-right {
    min-height: 80vh;
}

.modal-full-screen .panel-body {
    min-height: 70vh;
}

.calendar-header {
    background-color: #E8FAD5;
}

.sunday {
    color:red;
}

.saturday {
    color:blue;
}

.background_saturday_thin{
    background-color: #FFF3EE;
}
.background_sunday_thin{
    background-color: #FFEAE2;
}
.background_saturday_dark{
    background-color: #FFE1BC;
}
.background_sunday_dark{
    background-color: #FFDCBC;
}

.holiday-cell {
    color: black;
    background-color: #FFEAE2;
}

.midnight {
    background-color: #d6e9c6;
}

.confirmed {
    background-color: wheat;
    background: url("../images/confirmed.png") no-repeat center top;
    background-size: 45px Auto;
}

.confirmed-text {
}

.cropArea {
    background: #E4E4E4;
    overflow: hidden;
    width:450px;
    height:337px;
}


.no-image {
    display: inline-block;
    width: 200px;
    height: 200px;
    background: url("../images/no-image-icon.png") no-repeat center top;
    background-size: contain;
}
.time-format-error{
    color: red;
}
.home-top {
    height: 20px;
}
.home-info {
    color: #888888;
}
.home-link a {
    color: black;
    text-decoration: underline;
}
.home-link a:hover {
    color: black;
    text-decoration: underline;
}
.home-link>div {
    margin-bottom: 20px;
}
.home-div {
    background-color: #dff0d8;
    height: 350px;
    border-radius: 25px;
    font-size: 18px;
    cursor: pointer;
}
.caretree-free-top {
    height: 50px;
}
.analyze-top {
    height: 60px;
}
.caretree-free-image {
    background: url("../images/caretree-free.png") no-repeat center top;
    background-size: 80%;
    height: 220px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}
.asobu-image {
    background: url("../images/asobu.png") no-repeat center top;
    background-size: 70%;
    height: 250px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}
.dakoku-image {
    background: url("../images/dakoku.png") no-repeat center top;
    background-size: 70%;
    height: 250px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}
.carelis-image {
    background: url("../images/carelis_log.png") no-repeat center top;
    background-size: 60%;
    height: 250px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}
.analyze-image {
    background: url("../images/analyze.png") no-repeat center top;
    background-size: 60%;
    height: 210px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}
.mikkeru-image {
    background: url("../images/mikkeru.png") no-repeat center top;
    background-size: 80%;
    height: 270px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}
.home-beta {
    font-size: 12px;
    font-weight: bold;
}

.download-image {
    background: url("../images/down.png") no-repeat center;
}
.download-image:hover {
    background: url("../images/downBlue.png") no-repeat center;
}
.table-hover > tbody > tr:hover {
    background-color: #DCDCDC;
}
.table-dialog tbody {
    overflow-y: scroll; max-height: 300px; display: block;
}
.table-dialog tbody tr {
    display:table; table-layout: fixed; width: 100%;
}
.table-dialog tbody tr td {
    word-break: break-all; word-wrap: break-word;
}
.table-dialog thead tr {
    display:table; table-layout: fixed; width: 100%;
}
.button-add {
    margin-top: 20px;
}
.search-button{
    width: 100px;
    height: 40px;
}
.clear-button {
    margin-left: 30px;
    width: 100px;
    height: 40px;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    background-color: #fff;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    background-color: transparent;
}
.navbar-default {
    border-color: #fff;
}
.bgblue {
    background-color: #1E90FF;
}
.dropdown-menu > li.bgblue>a:hover {
    background-color: #1E90FF;
}
.ow-pt-title{
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0,#508b2b),color-stop(1,#66b237));
    padding: 20px 0;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0 1px 0 rgba(0,0,0,0.5);
    border: 1px solid #39641f;
    border-bottom: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 5px;
    background-clip: padding-box;
}
.ow-pt-subtitle {
    font-size: 0.9em;
    color: #F0F0F0;
}
.ow-pt-details {
    background: #72c43f;
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0,#68b638),color-stop(1,#72c43f));
    background: -ms-linear-gradient(bottom,#68b638,#72c43f);
    background: -moz-linear-gradient(center bottom,#68b638 0%,#72c43f 100%);
    background: -o-linear-gradient(#72c43f,#68b638);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#72c43f', endColorstr='#68b638', GradientType=0);
    position: relative;
    padding: 15px 25px;
    border-width: 1px;
    border-style: solid;
    border-color: #62aa35;
    border-top-color: #39641f;
    border-right-width: 0;
    -webkit-box-shadow: inset 0px -15px 25px rgba(0,0,0,0.05);
    -moz-box-shadow: inset 0px -15px 25px rgba(0,0,0,0.05);
    box-shadow: inset 0px -15px 25px rgba(0,0,0,0.05);
    text-align: center;
}
.ow-pt-price {
    color: #FFFFFF;
    font-size: 2.5em;
    line-height: 1.25em;
    font-weight: 300;
    text-shadow: 0 1px 1px rgba(0,0,0,0.25);
}
.ow-pt-per {
    color: #f3faee;
}
.price-detail {
    padding: 15px 20px;
    font-size: 0.925em;
    color: #5f6062;
    height: 100px;

}
.price-detail-1 {
    background: #f9f9f9;
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0,#f6f6f6),color-stop(1,#f9f9f9));
}
.price-detail-2 {
    background: #FFFFFF;
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0,#fcfcfc),color-stop(1,#FFFFFF));
}
.price-detail-3 {
    background: #eeeeee;
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0,#ebebeb),color-stop(1,#eeeeee));
}
.price-detail-4 {
    background: #f1f1f1;
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0,#eeeeee),color-stop(1,#f1f1f1));
}
.searchOne {
     text-align: left !important;
 }
.searchTwo {
    padding-left: 70px;
    text-align: left !important;
}
.time-empty-error{
    border-color: red;
}
